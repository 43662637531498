<template>
    <div class="container-fluid">
        <section class="content-header">
            <div class="row mb-2">
                <h3>{{ $t('general.Mensajes Predefinidos') }}</h3>
            </div>
        </section>
        <section class="content">
            <div class="row">
                <div class="col-md-2">
                </div>
                <div class="col-md-10">
                    <tabla-datos :columnas="columnas" :datos="mensajes_predefinidos" :filtros="filtros" :filtrosBuscador="filtrosBuscador"
                        :total="total" @quieroDatos="obtenerMensajesPredefinidos">
                    </tabla-datos>
                </div>
            </div>
        </section>
    </div>
    
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';
import TablaDatos from '../../../TablaDatos.vue';

export default {
    components: {
        "tabla-datos": TablaDatos,
    },
    data() {
        return {
            total: '',
            mensajes_predefinidos: [],
            parametros: null, //query params de DataTable
            filtrosBuscador: ['texto'], //filtros para el buscador
            filtros: { //filtros de columna
                'texto': { value: '', matchMode: 'contains' }
            },
            columnas: [
                {
                    header: 'Nombre',
                    field: 'titulo_texto',
                    sortable: true,
                },{
                    header: 'Texto',
                    field: 'texto',
                    sortable: true,
                },{
                    header: 'Tipo',
                    field: 'tipo_mensaje',
                    sortable: true,
                },{
                    header: 'Estado plantilla',
                    field: 'estado_plantilla',
                    sortable: true,
                },
                {
                    header: 'Acciones',
                    data: null,
                    acciones: [
                        
                        {
                            nombre: 'Editar',
                            class: 'editar',
                            tipo: 'default',
                            nombreId: 'id',
                            icono: 'far fa-edit',
                            // Debe ser arrow function para que this sea este componente
                            accion: (id) => {
                                
                                this.$router.push({
                                    name: 'Mensaje Predefinido',
                                    params: {
                                        id
                                    }
                                });
                                
                            }
                        },
                        {
                            nombre: 'Eliminar',
                            class: 'eliminar',
                            tipo: 'danger',
                            nombreId: 'id',
                            icono: 'far fa-trash-alt',
                            // Debe ser arrow function para que this sea este componente
                            accion: (id) => {
                                if (confirm('¿Desea eliminar el mensaje predefinido?')) {
                                    this.eliminar(id);
                                }
                            }
                        },
                    ]
                }
            ]
        }
    },
    methods: {
        async obtenerMensajesPredefinidos(parametros) {
            const api = new PwgsApi;
            const params = parametros;
            const aux = await api.get('mensajes-predefinidos', params);
            this.mensajes_predefinidos = aux.datos;
            this.total = aux.n_total_registros;
            this.mensajes_predefinidos.forEach(element => {
                switch (element.estado_plantilla){
                    case 'pending':
                        element.estado_plantilla = 'Pendiente';
                        break;
                    case 'received':
                        element.estado_plantilla = 'Pendiente';
                        break;
                    case 'approved':
                        element.estado_plantilla = 'Aprobado';
                        break;
                    case 'rejected':
                        element.estado_plantilla = 'Rechazado';
                        break;
                    case '':
                        element.estado_plantilla = '';
                        break;
                    default:
                        element.estado_plantilla = 'Otro';
                        break;
                }
            });
            this.columnas[0].header = this.$t("general.nombre");
            this.columnas[1].header = this.$t("general.acciones");
        },
        async eliminar(id) {
            const api = new PwgsApi;
            await api.delete('mensajes-predefinidos/' + id);
            this.obtenerMensajesPredefinidos();
        },
    },
    mounted() {
        this.obtenerMensajesPredefinidos();
    },    
    activated() {
        this.obtenerMensajesPredefinidos();
  },
}
</script>
<style>
.p-dialog {
    width: 90%;
}
</style>